<template>
  <OForm :submitting="submitting" @submit="submit">
    <CSocialLogin @login="onSocialLogin"></CSocialLogin>
    <OFormInput
      id="email"
      name="email"
      type="text"
      autocomplete="username"
      minlength="3"
      v-model="form.email"
      inputmode="email"
      required
      placeholder="Enter your email"
    >
      Email / Username
    </OFormInput>
    <OFormInput
      id="password"
      name="password"
      type="password"
      v-model="form.password"
      autocomplete="current-password"
      required
      placeholder="Enter your password"
    >
      Password
    </OFormInput>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Login
      </OFormSubmitButton>
    </FormButtons>
    <p class="info">
      <o-link :to="{name: 'forgot'}">Forgot password</o-link>
    </p>
  </OForm>
</template>

<script>
import { mapMutations } from 'vuex'
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormInput from '../objects/forms/OFormInput'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OLink from '../objects/OLink'
import OFormError from '../objects/forms/OFormError'
import CSocialLogin from '../components/CSocialLogin'

const getApi = () => import('@/services/api')

export default {
  name: 'CFormLogin',
  components: { OFormError, OLink, FormButtons, OFormSubmitButton, OFormInput, OForm, CSocialLogin },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapMutations(['setAuth', 'clearPhones']),
    afterLogin () {
      this.clearPhones()
    },
    submit () {
      this.$callApi(api => api.login(this.form))
    },
    onSocialLogin ({ social, credential }) {
      if (social === 'google') {
        this.onGoogleLogin(credential)
      } else if (social === 'apple') {
        this.onAppleLogin(credential)
      }
    },
    async onGoogleLogin (token) {
      const api = await getApi()
      const result = await api.googleLogin({ token })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    },
    async onAppleLogin (code) {
      const api = await getApi()
      const result = await api.appleLogin({ code })
      if (result.error) {
        this.error = result.error.message
        return false
      }
      this.setAuth({ email: result.data.username, password: result.data.password })
      this.afterLogin()
      this.$emit('success', result.data)
    }
  },
  formSubmitted () {
    this.setAuth(this.form)
    this.afterLogin()
  }
}
</script>
